import React from 'react';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { getRichTextEntityLinks } from '@contentful/rich-text-links';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';

const DEFAULT_LOCALE = "es-AR";

const options = (links, props={}) => ({
  renderMark: {
    [MARKS.BOLD]: text => <strong {...props}>{text}</strong>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Typography variant="body1" gutterBottom {...props}>{children}</Typography>,
    [BLOCKS.HEADING_1]: (node, children) => <Typography variant="h1" gutterBottom {...props}>{children}</Typography>,
    [BLOCKS.HEADING_2]: (node, children) => <Typography variant="h2" gutterBottom {...props}>{children}</Typography>,
    [BLOCKS.HEADING_3]: (node, children) => <Typography variant="h3" gutterBottom {...props}>{children}</Typography>,
    [BLOCKS.HEADING_4]: (node, children) => <Typography variant="h4" gutterBottom {...props}>{children}</Typography>,
    [BLOCKS.HEADING_5]: (node, children) => <Typography variant="h5" gutterBottom {...props}>{children}</Typography>,
    [BLOCKS.HEADING_6]: (node, children) => <Typography variant="h6" gutterBottom {...props}>{children}</Typography>,
    [BLOCKS.HR]: (node, children) => <Box paddingTop={3} paddingBottom={3}><Divider /></Box>,
    [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
      return <img src={node.data.target.fields.file[DEFAULT_LOCALE].url} style={{ margin: 'auto '}} />
    },
  },

  renderText: text => text,
});

const documentToReact = (document, props) => {
  const links = getRichTextEntityLinks(document);
  return documentToReactComponents(document, options(links, props));
};

export default documentToReact;

